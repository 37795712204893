<template>
  <div>
    <div class="header">游戏大厅</div>
    <div class="all">
      <div class="marquee flex">
        <van-notice-bar
          class="mar"
          id="marque"
          color="red"
          background="#fff"
          :left-icon="laba"
          :text="marquee"
          speed="40"
          v-if="marquee"
        />
      </div>
      <AdvSwiper :advList="gameAd" />
      <div class="section">
        <div class="money">
          <div class="title">钱包余额（元）</div>
          <div class="money-num">
            <span class="jb"></span>
            <span v-if="!isLoading">{{ banlance }}</span>
            <van-loading v-else color="red" size="24px" />
          </div>
          <router-link tag="div" to="/myBill" class="my-account"
            >我的账单<span class="go"></span
          ></router-link>
        </div>
        <div class="nav flex-between">
          <router-link tag="div" to="/gameWallet" class="phone flex-column">
            <div class="icon"></div>
            <div class="nav-title">充值</div>
            <div class="vip" v-if="userInfo.giftVipStatus == 1">返现</div>
          </router-link>
          <router-link tag="div" to="/withdraw" class="phone flex-column">
            <div class="icon"></div>
            <div class="nav-title">提现</div>
          </router-link>
          <div class="phone flex-column" @click="putPhone">
            <div class="icon"></div>
            <div class="nav-title">绑手机</div>
            <div class="vip">赠3元</div>
            <!-- <div class="vip" v-if="userInfo.firstBindGiftReceive == false">赠3元</div> -->
          </div>
          <router-link tag="div" to="/promote" class="phone flex-column">
            <div class="icon"></div>
            <div class="nav-title">全民代理</div>
          </router-link>
        </div>
        <div class="game" v-if="playedGame && playedGame.length > 0">
          <div class="title-played">最近玩过</div>
          <div class="played flex">
            <div
              class="list"
              v-for="item in playedGame"
              :key="item.id"
              @click="playgame(item)"
            >
              <img :src="item.img" alt="" />
              <p class="game-name">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="all-game">
          <div class="all-title">全部游戏</div>
          <div class="game-list flex-between">
            <div
              class="item"
              v-for="item in gameList"
              :key="item.id"
              @click="playgame(item)"
            >
              <img class="item-img" :src="item.img" alt="game" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wordsann :words="words" :img="img" />
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { getBanlance, queryGameWinLog } from "@/api/app.js";
import wordsann from "@/views/widget/wordsAnn.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { mapGetters } from "vuex";
import { Toast } from "vant";
export default {
  name: "game",
  components: {
    wordsann,
    AdvSwiper,
  },
  created() {
    if (this.waliWorldCupSwitch) {
      this.gameList.unshift({
        id: 100,
        img: require("@/assets/gameimg/wordCup.png"),
        name: "世界杯",
      });
    }
    if (!sessionStorage.getItem("gameFirstTime")) {
      //第一次进入
      sessionStorage.setItem("gameFirstTime", "yes");
      this.words = this.ann
        .filter((item) => {
          return item.type == 2;
        })
        .reverse(); //游戏文字公告
      this.img = this.ann
        .filter((item) => {
          return item.type == 3;
        })
        .reverse(); //游戏图片公告
    }
    this.gameAd = getAdItem(AdType.game);
    this.getBanlance();
    this.queryGameWinLog();
    this.playedGame = localStorage.getItem("playedGame");
    if (!this.playedGame) {
      localStorage.setItem("playedGame", JSON.stringify([]));
      this.playedGame = [];
    } else {
      this.playedGame = JSON.parse(this.playedGame);
    }
  },
  data() {
    return {
      laba: require("@/assets/png/laba.png"),
      ann: JSON.parse(sessionStorage.getItem("appConfig")).blts, //公告
      words: [], //游戏文字公告
      img: [], //游戏图片公告
      gameAd: [], //游戏广告
      banlance: 0, //钱包余额
      marquee: "",
      isLoading: false,
      // swiperOption: {
      //   observeParents: true,
      //   observe: true,
      //   // loop:true,
      //   autoplay: {
      //     delay: 10000,
      //     disableOnInteraction: false,
      //   },
      //   //显示分页
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true, //允许点击分页跳转
      //   },
      // },
      playedGame: null,
      gameList: [
        {
          id: 1,
          img: require("@/assets/gameimg/py.jpg"),
          name: "捕鱼",
        },
        {
          id: 2,
          img: require("@/assets/gameimg/ddz.jpg"),
          name: "斗地主",
        },
        {
          id: 3,
          img: require("@/assets/gameimg/zjh.jpg"),
          name: "炸金花",
        },
        {
          id: 4,
          img: require("@/assets/gameimg/brnn.jpg"),
          name: "百人牛牛",
        },
        {
          id: 5,
          img: require("@/assets/gameimg/qznn.jpg"),
          name: "抢庄牛牛",
        },
        {
          id: 6,
          img: require("@/assets/gameimg/ermj.jpg"),
          name: "二人麻将",
        },
        {
          id: 7,
          img: require("@/assets/gameimg/hhdz.jpg"),
          name: "红黑大战",
        },
        {
          id: 8,
          img: require("@/assets/gameimg/dzpk.jpg"),
          name: "德州扑克",
        },
        {
          id: 10,
          img: require("@/assets/gameimg/pdk.jpg"),
          name: "跑得快",
        },
        {
          id: 12,
          img: require("@/assets/gameimg/lhdz.jpg"),
          name: "龙虎大战",
        },
        {
          id: 14,
          img: require("@/assets/gameimg/xsbjl.jpg"),
          name: "视讯百家乐",
        },
        {
          id: 17,
          img: require("@/assets/gameimg/sb.jpg"),
          name: "骰宝",
        },
        {
          id: 18,
          img: require("@/assets/gameimg/pcbm.jpg"),
          name: "奔驰宝马",
        },
        {
          id: 19,
          img: require("@/assets/gameimg/fqzs.jpg"),
          name: "飞禽走兽",
        },
        {
          id: 24,
          img: require("@/assets/gameimg/slwh.jpg"),
          name: "森林舞会",
        },
        {
          id: 25,
          img: require("@/assets/gameimg/eyd.jpg"),
          name: "21点",
        },
        {
          id: 26,
          img: require("@/assets/gameimg/qzsg.jpg"),
          name: "抢庄三公",
        },
        {
          id: 27,
          img: require("@/assets/gameimg/kszqznn.jpg"),
          name: "看三张抢庄牛牛",
        },
        {
          id: 28,
          img: require("@/assets/gameimg/qzpj.jpg"),
          name: "抢庄牌九",
        },
        {
          id: 29,
          img: require("@/assets/gameimg/qzebg.jpg"),
          name: "抢庄二八杠",
        },
        {
          id: 41,
          img: require("@/assets/gameimg/lznn.png"),
          name: "癞子牛牛",
        },
        {
          id: 44,
          img: require("@/assets/gameimg/brttz.png"),
          name: "百人推筒子",
        },
        {
          id: 49,
          img: require("@/assets/gameimg/fkqznn.png"),
          name: "疯狂抢庄牛牛",
        },
        {
          id: 22,
          img: require("@/assets/gameimg/lhdb.png"),
          name: "连环夺宝",
        },
        {
          id: 34,
          img: require("@/assets/gameimg/thirteen.png"),
          name: "十三水",
        },
        {
          id: 50,
          img: require("@/assets/gameimg/srnn.png"),
          name: "四人牛牛",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      waliWorldCupSwitch: "waliWorldCupSwitch",
    }),
  },
  methods: {
    putPhone() {
      // if (this.userInfo.firstBindGiftReceive == false) {
      //   this.$router.push("/bindIphone");
      //   return;
      // }
      // this.$toast("您已绑定过手机号");
      if (
        JSON.parse(sessionStorage.getItem("userInfo")).mobile ||
        JSON.parse(sessionStorage.getItem("userInfo")).email
      ) {
        this.$toast("您已绑定过手机号");
      } else {
        this.$router.push("/bindIphone");
      }
    },
    async playgame(item) {
      //点击游戏
      let L = this.playedGame.length;
      for (let i = 0; i < L; i++) {
        if (this.playedGame[i].id == item.id) {
          this.playedGame.splice(i, 1);
          break;
        }
      }
      this.playedGame.unshift({
        id: item.id,
        img: item.img,
        name: item.name,
      });
      localStorage.setItem("playedGame", JSON.stringify(this.playedGame));
      if(!this.waliWorldCupSwitch && item.id == 100){
        Toast('世界杯游戏已结束'); 
        return;
      }
      this.$router.push({
        path: "/gameplay",
        query: {
          id: item.id,
        },
      });
    },
    async getBanlance() {
      //获取账户余额
      this.isLoading = true;
      let ret = await this.$Api(getBanlance, undefined);
      this.isLoading = false;
      if (ret.code == 200) {
        this.banlance = ret.data.wlBalance;
      }
    },
    async queryGameWinLog() {
      let ret = await this.$Api(queryGameWinLog);
      if (ret && ret.code == 200) {
        ret.data.forEach((i) => {
          this.marquee += `${i}                `;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 52px;
  background: $vermillion;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
}

.mar {
  flex: 1;
  font-size: 12px;
  height: 21px;

  // /deep/ .van-notice-bar__content {
  //   white-spsace: pre !important;
  // }

  /deep/ .van-notice-bar__left-icon {
    color: $vermillion;
  }
}

.laba {
  margin: 0 7px 0 2px;
  width: 15px;
  height: 15px;
  background: url("../../assets/png/laba.png") center center no-repeat;
  background-size: 100%;
}

.marquee {
  height: 21px;
  align-items: center;
}

.lunbo {
  height: 150px;
  position: relative;
}

.swiper-all {
  width: 100%;
  height: 100%;
}

.ad-img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 16px;
  bottom: 10px;
}

.swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-left: 6px;
  width: 6px;
  height: 6px;
}

.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 24px;
  background: #ffffff;
  border-radius: 4px;
}

.all {
  height: calc(100% - 52px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 30px;
  box-sizing: border-box;
  /deep/ .van-loading {
    display: inline-block;
  }
}

.section {
  padding: 12px 16px 0;
}

.money {
  height: 89px;
  padding: 6px 9px;
  box-sizing: border-box;
  border-radius: 13px;
  box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
}

.title {
  font-size: 10px;
  color: #515151;
}

.money-num {
  font-size: 24px;
  font-weight: bold;
}

.jb {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 16px 8px 10px 5px;
  background: url("../../assets/png/money.png") center center no-repeat;
  background-size: 100%;
  vertical-align: -12px;
}

.my-account {
  text-align: right;
  font-size: 10px;
  color: #515151;
}

.go {
  display: inline-block;
  width: 6px;
  height: 8px;
  background: url("../../assets/png/go_black.png") center center no-repeat;
  background-size: 100%;
  margin-left: 5px;
}

.nav {
  margin-top: 10px;
  margin-bottom: 17px;
}

.phone {
  width: 70px;
  height: 70px;
  border-radius: 11px;
  box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.icon {
  width: 26px;
  height: 26px;
  background: url("../../assets/png/money.png") center center no-repeat;
  background-size: 100%;
}

.nav-title {
  color: #1d1b1b;
  font-size: 12px;
  margin-top: 6px;
}

.vip {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 15px;
  line-height: 15px;
  color: #ffffff;
  background: $vermillion;
  font-size: 10px;
  transform: rotate(-45deg);
  left: -22px;
  top: 6px;
}

.phone:nth-child(2) > .icon {
  width: 26px;
  height: 26px;
  background: url("../../assets/png/withdraw.png") center center no-repeat;
  background-size: 100%;
}

.phone:nth-child(3) > .icon {
  width: 26px;
  height: 26px;
  background: url("../../assets/png/phone.png") center center no-repeat;
  background-size: 100%;
}

.phone:nth-child(4) > .icon {
  width: 26px;
  height: 26px;
  background: url("../../assets/png/agent.png") center center no-repeat;
  background-size: 100%;
}

.game {
  border-top: 1px solid #ededed;
}

.title-played {
  margin-top: 10px;
  color: #1d1b1b;
  font-size: 18px;
}

.played {
  margin-top: 9px;
  overflow-x: auto;
}

.played > div {
  flex-shrink: 0;
  width: 112px;
  text-align: center;
}

.list {
  margin-right: 19px;
}

.list > img {
  width: 100%;
  height: 78px;
  border-radius: 9px;
}

.list > p {
  margin-top: 4px;
  font-size: 16px;
  color: #1d1b1b;
}

.all-game {
  margin-top: 18px;
}

.all-title {
  font-size: 18px;
  color: #1d1b1b;
}

.game-list {
  flex-wrap: wrap;
}

.game-list > div {
  width: 168px;
  text-align: center;
  margin-top: 10px;
}

.item-img {
  width: 100%;
  height: 120px;
  border-radius: 9px;
  overflow: hidden;
}

.item > p {
  margin-top: 7px;
  font-size: 16px;
  color: #1d1b1b;
}

#marque {
  /deep/ .van-notice-bar__content {
    white-space: pre !important;
  }
}
</style>
